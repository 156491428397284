<template>
  <div class="product-details-wrapper">
    <div class="nav-details">
      <div><span @click="$router.push('/product/skylightLamp')">{{  $t('EWINDOW')}} </span> > {{$t('productIntroduction')}}</div>
    </div>
    <div class="product-details">
      <div
        v-if="isFindProduct && product.title"
      >
        <div
          v-show="showType === 'img'"
          class="img-swiper"
        >
         <swiper
            v-if="mySwiperLoad"
            class="product-details-components-swiper"
            :options="swiperOptions"  
          >
            <template
              v-for="(item, index) in list"
            >
              <swiper-slide
                :key="index"
                class="product-details-slide"
              > 
                <div
                  class="product-details-slide-img"
                >
                  <img
                    :src="item"
                  />
                </div>
              </swiper-slide>
            </template>
          </swiper>
          <swiper
            ref="mySwiper"
            class="product-details-components-sec-swiper"
            :options="swiperOptionsSec"
            @ready="swiperLoad"
          >
            <template
              v-for="(item, index) in list"
            >
              <swiper-slide
                :key="index"
                class="product-details-sec-slide"
              > 
                <div
                  class="product-details-slide-sec-img"
                >
                  <img
                    :src="item"
                  />
                </div>
              </swiper-slide>
            </template>
          </swiper>
        </div>
        <div
          v-if="showType !== 'img'"
          class="product-details-video-wrpapper"
        >
          <video class="product-details-video" controls autoplay>
            <source :src="video" type="video/mp4" />
          </video>
        </div>
        <div class="product-details-des">
          <div>
            <div>{{ product.title }}</div>
            <!-- <div>{{ product.desction }}</div> -->
          </div>
          <div>
            <div
              @click="showType = 'img'"
              :class="showType === 'img' ? 'active' : ''"
            >
              {{ $t('image') }}
            </div>
            <div
              v-if="product.video"
              @click="showType = 'video'"
              :class="showType !== 'img' ? 'active' : ''"
            >
              {{ $t('video') }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="no-article"
        v-if="isFindProduct && !product.title"
      >
        <div>未找到该产品</div>
      </div>
    </div>
    <div class="product-details-information">
      <div class="product-details-information-content">
        <!-- <div class="product-details-information-nav">
          <div
            :key="index"
            v-for="(item, index) in $t('productIntroductionNav')"
            @click="navActive = index"
            :class="navActive === index ? 'active' : ''"
          >
            {{ item }}
          </div>
        </div> -->
        <div class="product-details-information-content">
          <div class="product-details-information-download" v-if="propKeys[navActive] === 'picture_album'">
            <div>
              <img src="@/assets/image/product/s5.jpg" alt="">
            </div>
            
            <button
              @click="download"
            >
              {{ $t('xiazaihuace') }}
            </button>
          </div>
          <div class="product-details-information-content-w-e" v-else v-html="product[propKeys[navActive]]"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { showMessage, goGet } from '@/utils'
export default {
  name: 'ProductDetails',
  metaInfo() {
    return {
      title: this.$t('product')
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      isFindProduct: false,
      product: {},
      showType: 'video',
      imgActive: 0,
      navActive: 0,
      mySwiperLoad: false,
      swiper: null,
      swiperOptions: {},
      swiperOptionsSec: {
        direction: "vertical",
        spaceBetween: 20,
        slidesPerView: 'auto',
        freeMode: true,
        watchSlidesProgress: true,
      },
      propKeys: ['parameters', 'control', 'diagram', 'circuitDiagram', 'picture_album']
    }
  },

  computed: {
    list() {
      return this.product.cover.split(',').map(list => process.env.VUE_APP_API + list)
    },
    video() {
      return process.env.VUE_APP_API + this.product.video
    }
  },

  mounted() {
    const { id } = this.$route.params
    id ? this.productDetails(id) : showMessage('未找到对应产品', 'error')
  },
  methods: {
    swiperLoad() {
      this.swiper = this.$refs.mySwiper.$swiper
      this.swiperOptions.thumbs  = this.swiper 
      this.swiperOptions = {
        thumbs: {
          swiper: this.swiper,
        }
      },
      this.mySwiperLoad = true
    },
    productDetails(id) {
      goGet('/ewinlight/product/find', {id: id}).then(res => {
        this.isFindProduct = true
        if (res.code === 200) {
          this.product = res.data
          this.showType = this.product.video ? 'video' : 'img'
          const reg = new RegExp('https://nestjs.mxliu.top/api','g')
          this.product.parameters = res.data.parameters.replace(reg, process.env.VUE_APP_API)
        }
      })
    },
    download() {
      this.product.picture_album ? window.open(process.env.VUE_APP_API + this.product.picture_album) : showMessage(this.$t('nofile'), 'error')
    }
  }
}
</script>

<style lang="scss" scoped>
.product-details-wrapper {
  .nav-details{
    width: 100%;
    height: 70px;
    background: rgba(245, 246, 247, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    &>div {
      width: 100%;
      max-width: 1200px;
      &>span {
        cursor: pointer;
      }
    }
  }
  .product-details {
    .img-swiper {
      display: flex;
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      margin-top: 70px;
      justify-content: space-between;
      .product-details-components-swiper {
        width: 84%;
        height: 674px;
        .product-details-slide-img {
          width: 100%;
          height: 674px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 100%;
          }
        }
      }
      .product-details-components-sec-swiper {
        width: 14%;
        height: 674px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        ::v-deep .swiper-wrapper {
          justify-content: center;
        }
        .product-details-sec-slide {
          width: 168px;
          height: 158px;
          overflow: hidden;
          opacity: 0.5;
          transition: opacity 0.3s;
          &.swiper-slide-thumb-active {
            opacity: 1;
            transition: opacity 0.3s;
          }
          .product-details-slide-sec-img {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            img {
              width: 100%;
            }
          }
        }
      }
    }
    .product-details-video-wrpapper {
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;
      margin-top: 70px;
      .product-details-video {
        width: 100%;
        height: 674px;
      }
    }
    .product-details-des {
      display: flex;
      width: 100%;
      max-width: 1200px;
      justify-content: space-between;
      height: 54px;
      align-items: center;
      margin: 0 auto;
      margin-top: 50px;
      margin-bottom: 60px;
      &>:nth-child(1) {
        width: 70%;
        &>:nth-child(1) {
          color: #222222;
          line-height: 54px;
          font-size: 30px;
          font-weight: bold;
        }
        &>:nth-child(2) {
          height: 26px;
          overflow: hidden;
          line-height: 26px;
          font-size: 16px;
          color: rgba(51, 51, 51, 1);
          margin-top: 10px;
        }
      }
      &>:nth-child(2) {
        display: flex;
        &>div {
          width: 128px;
          height: 47px;
          border-radius: 23px;
          overflow: hidden;
          font-size: 16px;
          color: rgba(51, 51, 51, 1);
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: all 0.3s;
          margin: 0 5px;
          &.active, &:hover {
            background: rgba(51, 51, 51, 1);
            color: rgba(255, 255, 255, 1);
            transition: all 0.3s;
          }
        }
      }
    }
  }
  .product-details-information {
    padding-top: 40px;
    padding-bottom: 106px;
    border-top: 1px solid rgba(224, 224, 224, 1);
    width: 100%;
    .product-details-information-nav {
      display: flex;
      height: 50px;
      justify-content: center;
      &>div {
        margin: 0 20px;
        cursor: pointer;
        height: 50px;
        box-sizing: border-box;
        border-bottom: 3px solid rgba(255, 255, 255, 1);
        transition: all 0.3s;
        &:hover, &.active {
          border-bottom: 3px solid rgba(51, 51, 51, 1);
          transition: all 0.3s;
        }
      }
    }
    .product-details-information-content-w-e {
      max-width: 1200px;
      margin: 0 auto;
      width: 100%;
      ::v-deep .w-e-content-container {
        font-size: 18px;
        p {
          color: rgba(51, 51, 51, 1);
          font-size: 18px;
          margin-bottom: 40px;
          line-height: 32px;
          img {
            max-width: 935px;
          }
        }
      }
    }
    .product-details-information-download {
      max-width: 1200px;
      margin: 0 auto;
      &>div {
        display: flex;
        justify-content: center;
        margin-top: 65px;
      }
      &>button {
        width: 109px;
        height: 41px;
        background: #333333;
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        border: 0;
        outline: none;
        cursor: pointer;
        display: block;
        margin: 0 auto;
        margin-top: 47px;
      }
    }
  }
}
</style>